import $ from 'jquery';
//import jQuery from 'jquery';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';

import './scss/app.scss';

window.$ = $;

$(document).ready(function(){
	console.log('document ready');

	// Mobile menu
	function mobileMenu(){
		console.log('test');
		$('.hamburger').on('click', function(){

			if($(this).hasClass('is-active')){
				$(this).removeClass('is-active');
				$('.hamburger-text').removeClass('is-active');
				$('.menu-overlay').removeClass('is-active');
			}else{
				$(this).addClass('is-active');
				$('.menu-overlay').addClass('is-active');				
				$('.hamburger-text').addClass('is-active');
			}
		});
	}

	mobileMenu();

	// Galerij - Projecten
	function projectenGallery(){
		$('.projecten__cats a').on('click', function(){
			
			var hash = $(this).attr('href');
			hash = hash.replace(/^#/, '');

			console.log( hash );

			$('.project').each(function(index){
				if($(this).hasClass(hash)){
					$(this).addClass('reveal');
				}else{
					$(this).removeClass('reveal');
				}
			})

		});		
	}

	projectenGallery();
});